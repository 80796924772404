import React, { useState, useEffect } from "react"
import Layout from "../components/common/layout"
import { StaticImage } from "gatsby-plugin-image"
import HeroSection from "../components/hero-section"

import PartnerSlider from "../components/partner-slider"
import ContactFooter from "../components/contact-footer"
import SpeechBubbleList from "../components/referenzen/speechbubbleReference"
import Video from "../components/common/video"
import {
  Videocontainer,
  BGLinearGradient,
  Separator,
  SeparatorColorFull,
} from "../components/common/styledComponentsHelper"
import { implementBierdeckelText } from "../static/text/referenzenContent"
import { videoCaptionText, customerReview } from "../static/text/commonContent"
import AppointletButton from "../components/common/appointletButton"
import VideoWidthCaption from "../components/video-with-caption"
import ReviewContainer from "./../components/review-container"

export const Head = () => (
  <>
    <title>Referenzen | Fachhandwerk360</title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const Referenzen = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const scriptTag = document.createElement("script")
    scriptTag.src =
      "https://www.provenexpert.com/widget/landing_fachhandwerk360-dreier-herber-gbr.js?feedback=0&avatar=0&competence=0&style=black"
    scriptTag.addEventListener("load", () => setLoaded(true))
    document.body.appendChild(scriptTag)
  }, [])

  useEffect(() => {
    if (!loaded) return
  }, [loaded])

  return (
    <Layout>
      <HeroSection
        desktopImg="referenzen_banner_desktop.png"
        mobileImg="referenzen_banner_mobile.png"
        headlineText="Referenzen"
        responsiveHeadline="Referenzen"
        subheadlineText="Das sagen unsere Kunden aus dem Bauhandwerk"
        captionText=""
      />
      <BGLinearGradient>
        <Separator />
        <div className="container p-sm-5 p-4">
          <VideoWidthCaption
            headlineText={
              <>
                Endlich mehr <span className="text-success">Gewinn!</span>
              </>
            }
            videoURL="https://www.youtube-nocookie.com/embed/KzZwXMKM3zo?rel=0"
            videoCaption="Die Bierdeckelkalkulation - Das sagen unsere Handwerkskunden!"
            captionText={customerReview}
          />
        </div>
        <SeparatorColorFull />
        <div className="bg-warning bg-opacity-25">
          <ReviewContainer />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-12">
                <div id="pewl" style={{ maxWidth: "100%" }}></div>
              </div>
            </div>
          </div>
          <Separator />
        </div>
        <Separator />
        <div className="container">
          <h2 className="text-light text-uppercase text-bold text-center fw-bold">
            Auszüge <span className="text-success">Referenzen</span>
          </h2>
        </div>
        <div className="container">
          <SpeechBubbleList />
        </div>
        <div className="container">
          <div className="row flex-lg-row-reverse">
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <table className="h-100">
                <tbody>
                  <tr>
                    <td className="pt-3 pb-3 align-middle">
                      <h2 className="text-uppercase text-light fw-bold">
                        Bierdeckel<span className="d-sm-none d-inline"> </span>
                        kalkulation{" "}
                        <span className="text-success">jetzt umsetzen</span>
                      </h2>
                      <div className="text-light mt-4 fs-5">
                        {implementBierdeckelText}
                      </div>
                      <br />
                      <AppointletButton />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <StaticImage
                src="../images/seminare/vortrag_buchen.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Jetzt Vortrag buchen"
                placeholder="none"
              />
            </div>
          </div>
          <Separator />
          <div className="row">
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <table className="h-100 w-100">
                <tbody>
                  <tr>
                    <td className="pt-3 pb-3 align-middle text-end">
                      <h2 className="text-uppercase text-light fw-bold">
                        Wie komme ich zu mehr{" "}
                        <span className="text-success">Gewinn?</span>
                      </h2>
                      <div className="text-light mt-4 fs-5">
                        {videoCaptionText}
                      </div>
                      <br />
                      <AppointletButton />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
              <Videocontainer>
                <Video
                  videoSrcURL="https://www.youtube-nocookie.com/embed/zzSkQNJyqPQ?rel=0"
                  videoTitle="Die Bierdeckelkalkulation - So funktioniert sie!"
                  videoStyle={{
                    top: "0",
                    left: "0",
                    height: "100%",
                  }}
                />
              </Videocontainer>
            </div>
          </div>
        </div>
        <Separator />
        <ContactFooter />
        <PartnerSlider />
      </BGLinearGradient>
    </Layout>
  )
}

export default Referenzen

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SingelCustomerImg = ({ filename, alt, style, className }) => (
  <StaticQuery
    query={graphql`
      query {
        singelCustomers: allFile(
          filter: { relativeDirectory: { eq: "referenzen/singelCustomers" } }
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 95
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    `}
    render={data => {
      const singelCustomers = data.singelCustomers.edges.find(n => {
        return n.node.relativePath.includes(
          "referenzen/singelCustomers/" + filename + ".jpg"
        )
      })
      const sources = getImage(
        singelCustomers.node.childImageSharp.gatsbyImageData
      )
      return (
        <GatsbyImage
          image={sources}
          alt={alt}
          style={style}
          className={className}
        />
      )
    }}
  />
)

SingelCustomerImg.defaultProps = {
  className: ``,
}

export default SingelCustomerImg

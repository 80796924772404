import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { dangerColor } from "../../static/styles/colors"
import customerReferences from "../../static/utils/customerReferences.json"
import SingelCustomerImg from "./singelCustomerImg"
import { IoStarSharp } from "@react-icons/all-files/io5/IoStarSharp"
import { ImQuotesLeft } from "@react-icons/all-files/im/ImQuotesLeft"
import { ImQuotesRight } from "@react-icons/all-files/im/ImQuotesRight"
import TextCompactor from "../common/textCompactor"

const SpeechBubbleList = () => {
  const [textLength, setTextLength] = useState(350)
  useEffect(() => {
    window.innerWidth >= 992 ? setTextLength(350) : setTextLength(100)
  }, [])

  return (
    <div className="container">
      <Separator />
      {customerReferences.customers.map(customer => {
        const { id, name, company, comment } = customer
        return (
          <div key={id} className="mb-5">
            <div className="row">
              <div className="col-lg-3">
                <div className="d-lg-block d-flex align-items-center">
                  <SingelCustomerImg
                    filename={name}
                    alt={name}
                    path="singelCustomers"
                    className="w-75"
                    style={{ minWidth: "150px", maxWidth: "250px" }}
                  />
                  <div className="ps-lg-0 ps-3 ">
                    <div className="text-light fw-bold">
                      Bewertung:{" "}
                      <br className="d-xl-none d-lg-block d-sm-none d-block" />
                      <IoStarSharp
                        size="25px"
                        className="d-sm-inline-flex d-none"
                        color="rgb(200 178 116)"
                      />
                      <IoStarSharp
                        size="25px"
                        className="d-sm-inline-flex d-none"
                        color="rgb(200 178 116)"
                      />
                      <IoStarSharp
                        size="25px"
                        className="d-sm-inline-flex d-none"
                        color="rgb(200 178 116)"
                      />
                      <IoStarSharp
                        size="25px"
                        className="d-sm-inline-flex d-none"
                        color="rgb(200 178 116)"
                      />
                      <IoStarSharp
                        size="25px"
                        className="d-sm-inline-flex d-none"
                        color="rgb(200 178 116)"
                      />
                      <IoStarSharp
                        size="20px"
                        className="d-sm-none d-inline-flex"
                        color="rgb(200 178 116)"
                      />
                      <IoStarSharp
                        size="20px"
                        className="d-sm-none d-inline-flex"
                        color="rgb(200 178 116)"
                      />
                      <IoStarSharp
                        size="20px"
                        className="d-sm-none d-inline-flex"
                        color="rgb(200 178 116)"
                      />
                      <IoStarSharp
                        size="20px"
                        className="d-sm-none d-inline-flex"
                        color="rgb(200 178 116)"
                      />
                      <IoStarSharp
                        size="20px"
                        className="d-sm-none d-inline-flex"
                        color="rgb(200 178 116)"
                      />
                    </div>
                    <div className="text-light">
                      <span className="fw-bold">Name:</span>{" "}
                      <br className="d-xl-none d-lg-block d-sm-none d-block" />
                      {name}
                    </div>
                    <ResponsiveTextbox className="text-light">
                      <span className="fw-bold">Firma:</span>{" "}
                      <br className="d-xl-none d-lg-block d-sm-none d-block" />
                      {company}
                    </ResponsiveTextbox>
                  </div>
                </div>
              </div>
              <SpeechBubble className="col-lg-9">
                <table className="h-75">
                  <tbody>
                    <tr>
                      <td className="px-lg-5 px-3 blockquote text-light">
                        <ImQuotesLeft size="15px" />
                        <div>
                          <TextCompactor
                            fullText={comment}
                            textLengthToShow={textLength}
                          />
                        </div>
                        <ImQuotesRight size="15px" className="float-end" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </SpeechBubble>
            </div>
          </div>
        )
      })}
      <Separator />
    </div>
  )
}

const ResponsiveTextbox = styled.div`
  width: 75%;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`

const SpeechBubble = styled.div`
  padding: 50px 50px;
  margin: auto auto;
  background-color: ${dangerColor};
  border-radius: 10px;
  position: relative;
  :before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 30px solid transparent;
    border-right: 30px solid ${dangerColor};
    border-top: 30px solid ${dangerColor};
    border-bottom: 30px solid transparent;
    left: -50px;
    top: 100px;
  }
  @media screen and (max-width: 992px) {
    padding: 25px 25px;
    margin-top: 60px !important;
    :before {
      border-left: 20px solid ${dangerColor};
      border-right: 20px solid transparent;
      border-top: 20px solid transparent;
      border-bottom: 20px solid ${dangerColor};
      left: 50px;
      top: -30px;
    }
  }
`

const Separator = styled.hr`
  border: 5px solid transparent;
  padding: 2% 0px 2% 0px;
  margin: 0px;
  opacity: 0;
`

export default SpeechBubbleList
